import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { username, gender } = location.state || { username: 'Anonymous', gender: '' };

  // Navigation handler
  const navigateTo = (path) => {
    navigate(path, { state: { username, gender } });
  };

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white p-6">
      <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-center">
        Hi <span className="text-purple-400">{username}</span>, <br /> Welcome to Unsaid!
      </h1>
      <h2 className="text-lg md:text-xl lg:text-2xl mb-6 text-center">
        Your Profile: <span className="text-purple-400">{gender || 'Not Specified'}</span>
      </h2>
      <p className="text-md md:text-lg lg:text-xl text-center mb-8">
        Connect anonymously and explore your surroundings.<br /> Start chatting or dive into our marketplace* for unique conversations. 
      </p>

      <div className="flex flex-col gap-6 items-center w-full max-w-lg mx-auto">
        {/* Join Chat Room Button */}
        <button
          onClick={() => navigateTo('/room')}
          className="px-8 py-4 w-full md:w-auto bg-gradient-to-r from-purple-500 to-purple-700 text-white rounded-full shadow-lg hover:from-purple-600 hover:to-purple-800 transition-all transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-purple-400"
          aria-label="Join a Nearby Chat Room"
        >
          Join a Nearby Chat Room
        </button>

        {/* Explore Marketplace Button */}
        <button
          onClick={() => navigateTo('/marketplace')}
          className="px-8 py-4 w-full md:w-auto bg-gradient-to-r from-blue-500 to-blue-700 text-white rounded-full shadow-lg hover:from-blue-600 hover:to-blue-800 transition-all transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-blue-400"
          aria-label="Explore the Marketplace"
        >
          Explore the Marketplace
        </button>

        {/* Delete Profile Button */}
        <button
          onClick={() => navigate('/set-profile')}
          className="px-8 py-4 w-full md:w-auto bg-gradient-to-r from-pink-500 to-pink-700 text-white rounded-full shadow-lg hover:from-pink-600 hover:to-pink-800 transition-all transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-pink-400"
          aria-label="Delete My Profile and Reset"
        >
          Delete My Profile & Reset
        </button>
      </div>
    </div>
  );
};

export default Home;
