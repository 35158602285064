import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SetProfile from './pages/SetProfile';
import Home from './pages/Home';
import Room from './pages/Room';
import './App.css';
import Landing from './pages/Landing';
import Marketplace from './pages/Marketplace';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import ContactUs from './pages/ContactUs';

const NotFound = () => (
  <div className="text-center text-white mt-20">
    <h1 className="text-4xl">404</h1>
    <p className="text-lg mt-2">Page Not Found</p>
  </div>
);

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="*" element={<NotFound />} />
      <Route path="/set-profile" element={<SetProfile />} />
      <Route path="/home" element={<Home />} />
      <Route path="/room" element={<Room />} />
      <Route path="/marketplace" element={<Marketplace />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-service" element={<TermsOfService />} />
      <Route path="/contact-us" element={<ContactUs />} />
    </Routes>
  </Router>
);

export default App;
