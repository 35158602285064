import React from 'react';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white p-6">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl md:text-4xl font-bold text-purple-400 mb-6 text-center">
          Terms of Service
        </h1>
        <p className="text-lg mb-4">
          Welcome to <span className="text-purple-400 font-semibold">Unsaid</span>. By using our platform, you agree to the terms outlined below. Please read them carefully before engaging with our services.
        </p>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">1. Acceptance of Terms</h2>
        <p className="mb-4">
          By accessing or using <span className="text-purple-400">Unsaid</span>, you agree to comply with these Terms of Service and our Privacy Policy. If you do not agree, please refrain from using the app.
        </p>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">2. Use of the Service</h2>
        <ul className="list-disc list-inside space-y-2">
          <li>
            <span className="font-semibold">Eligibility:</span> You must be at least 18 years old or have parental consent to use Unsaid.
          </li>
          <li>
            <span className="font-semibold">Anonymous Chatting:</span> You agree to use the platform responsibly without engaging in harassment, abuse, or spamming.
          </li>
          <li>
            <span className="font-semibold">Location Services:</span> You consent to the use of geolocation to connect you with nearby chat rooms or marketplaces.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">3. Prohibited Activities</h2>
        <p className="mb-4">
          While using <span className="text-purple-400">Unsaid</span>, you agree not to:
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>Violate any applicable laws or regulations.</li>
          <li>Use the platform to distribute harmful, obscene, or illegal content.</li>
          <li>Impersonate others or provide false information.</li>
          <li>Attempt to hack, disrupt, or exploit the platform or its users.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">4. Content Ownership</h2>
        <ul className="list-disc list-inside space-y-2">
          <li>
            <span className="font-semibold">User-Generated Content:</span> You retain ownership of the content you post but grant Unsaid a license to use it for service-related purposes.
          </li>
          <li>
            <span className="font-semibold">Content Moderation:</span> We reserve the right to remove any content that violates these terms or is deemed inappropriate.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">5. Limitation of Liability</h2>
        <p className="mb-4">
          <span className="text-purple-400">Unsaid</span> is provided "as is" without warranties of any kind. We are not responsible for any damages arising from your use of the platform.
        </p>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">6. Termination</h2>
        <p className="mb-4">
          We reserve the right to suspend or terminate your access to Unsaid if you violate these terms or engage in activities that harm the platform or its users.
        </p>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">7. Changes to Terms</h2>
        <p className="mb-4">
          These terms may be updated periodically. We will notify users of significant changes by posting them here with an updated date.
        </p>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">8. Contact Us</h2>
        <p>
          If you have any questions about these terms, please contact us at: 
          <span className="text-purple-400"> unsaidweb.contact@gmail.com
          </span>.
        </p>

        <p className="text-sm text-gray-400 mt-8">
          Last updated: {new Date().toLocaleDateString()}
        </p>
      </div>
    </div>
  );
};

export default TermsOfService;
