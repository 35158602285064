import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white p-6">
      <div className="max-w-4xl mx-auto">
        <h1 className="text-3xl md:text-4xl font-bold text-purple-400 mb-6 text-center">
          Privacy Policy
        </h1>
        <p className="text-lg mb-4">
          Welcome to <span className="text-purple-400 font-semibold">Unsaid</span>. We prioritize your privacy and are committed to protecting your personal information. This policy outlines how we handle, store, and protect the data you share with us.
        </p>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">1. Information We Collect</h2>
        <ul className="list-disc list-inside space-y-2">
          <li>
            <span className="font-semibold">Anonymous Usage:</span> We do not require personally identifiable information to use Unsaid. Users remain anonymous within the 5 km chat radius.
          </li>
          <li>
            <span className="font-semibold">Geolocation:</span> With your permission, we collect approximate location data to connect you to nearby chat rooms or marketplaces.
          </li>
          <li>
            <span className="font-semibold">Device Data:</span> Information like IP address and browser type may be collected for analytics and security purposes.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">2. How We Use Your Information</h2>
        <p className="mb-4">
          Your data is used solely to provide and improve our services. This includes:
        </p>
        <ul className="list-disc list-inside space-y-2">
          <li>Facilitating anonymous communication within the 5 km radius.</li>
          <li>Enhancing app functionality and user experience.</li>
          <li>Preventing misuse, spam, and abuse of the platform.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">3. Data Sharing and Security</h2>
        <ul className="list-disc list-inside space-y-2">
          <li>
            <span className="font-semibold">No Data Sharing:</span> We do not sell, trade, or share your data with third parties.
          </li>
          <li>
            <span className="font-semibold">Encryption:</span> All messages and sensitive data are encrypted to protect user privacy.
          </li>
          <li>
            <span className="font-semibold">Third-Party Services:</span> If we use external analytics tools, they comply with global data protection regulations.
          </li>
        </ul>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">4. Your Rights</h2>
        <ul className="list-disc list-inside space-y-2">
          <li>Access, modify, or delete your data by contacting us directly.</li>
          <li>Withdraw consent for location or any data collection at any time.</li>
        </ul>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">5. Updates to This Policy</h2>
        <p className="mb-4">
          We may update this policy to reflect changes in our practices or for operational, legal, or regulatory reasons. The updated policy will be posted on this page with a revised date.
        </p>

        <h2 className="text-2xl font-semibold text-purple-300 mt-8 mb-4">6. Contact Us</h2>
        <p>
          If you have any questions or concerns regarding this privacy policy, please reach out to us at: 
          <span className="text-purple-400"> support@un-said.com</span>.
        </p>

        <p className="text-sm text-gray-400 mt-8">
          Last updated: {new Date().toLocaleDateString()}
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
