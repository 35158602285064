import React from 'react';
import './MessageInput.css';

const MessageInput = ({ message, setMessage, handleKeyDown, sendMessage }) => (
  <div className="flex items-center p-4 bg-gray-900 border-t border-gray-700 message-input-container">
    <textarea
      className="flex-1 p-2 bg-gray-800 text-white rounded-lg outline-none focus:ring-2 focus:ring-primary message-input"
      placeholder="Type your message"
      value={message}
      onChange={(e) => setMessage(e.target.value)}
      onKeyDown={handleKeyDown}
    />
    {message.trim() && (
      <button
        className="ml-4 px-4 py-2 bg-primary text-white rounded-full hover:bg-accent transition send-button"
        onClick={sendMessage}
        onMouseDown={() => document.querySelector('.send-button').style.transform = 'scale(0.95)'}
        onMouseUp={() => document.querySelector('.send-button').style.transform = 'scale(1)'}
        onMouseLeave={() => document.querySelector('.send-button').style.transform = 'scale(1)'}
      >
        Send
      </button>
    )}
  </div>
);

export default MessageInput;
