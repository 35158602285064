import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const generateRandomUsername = () => {
  const adjectives = ['Charming', 'Witty', 'Bold', 'Brilliant', 'Friendly'];
  const nouns = ['Explorer', 'Dreamer', 'Traveler', 'Thinker', 'Doer'];
  const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
  const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];
  const randomNumber = Math.floor(Math.random() * 1000);

  return `${randomAdjective}${randomNoun}${randomNumber}`;
};

const SetProfile = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('Anonymous');
  const [customUsername, setCustomUsername] = useState('');
  const [gender, setGender] = useState('');
  const [error, setError] = useState('');
  console.log(username)

  const handleGenerateUsername = () => {
    if (!gender) {
      setError('Please select a gender before proceeding.');
      return;
    }
    const randomUsername = generateRandomUsername();
    setUsername(randomUsername);
    setError('');
    navigate('/home', { state: { username: randomUsername, gender } });
  };

  const handleSetUsername = () => {
    if (!gender) {
      setError('Please select a gender before proceeding.');
      return;
    }
    if (customUsername.trim()) {
      setUsername(customUsername.trim());
      setError('');
      navigate('/home', { state: { username: customUsername.trim(), gender } });
    } else {
      setError('Custom username cannot be empty.');
    }
  };

  const handleGenderChange = (e) => {
    setGender(e.target.value);
    setError('');
  };

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white p-6">
      <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-center">
        Set Up Your Profile
      </h1>
      <div className="text-center mt-6 w-full">
        <p className="text-lg mb-4">Choose a username to continue:</p>
        <div className="flex flex-col items-center gap-4 w-full max-w-lg mx-auto">
          <input
            type="text"
            placeholder="Enter your custom username"
            value={customUsername}
            onChange={(e) => setCustomUsername(e.target.value)}
            className="p-3 w-72 md:w-80 lg:w-96 bg-transparent border-2 border-purple-400 rounded-lg text-white focus:border-purple-600 outline-none"
          />

          <div className="mt-6 w-full">
            <p className="text-lg mb-2">Select your gender:</p>
            <select
              value={gender}
              onChange={handleGenderChange}
              className="w-72 md:w-80 lg:w-96 p-3 bg-transparent border-2 border-purple-400 rounded-lg text-white focus:border-purple-600 outline-none"
            >
              <option value="">-- Select Gender --</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Non-Binary">Non-Binary</option>
              <option value="Other">Other</option>
            </select>
          </div>

          <div className="flex flex-col gap-4 w-full">
            <button
              onClick={handleSetUsername}
              className="bg-purple-600 hover:bg-purple-500 text-white px-6 py-3 rounded-full font-semibold shadow-md transition"
              >
              Set Username
            </button>
            <button
              onClick={handleGenerateUsername}
              className="bg-pink-600 hover:bg-pink-500 text-white px-6 py-3 rounded-full font-semibold shadow-md transition"
            >
              Generate Random Username
            </button>
          </div>
        </div>

        {error && <p className="mt-4 text-pink-500">{error}</p>}
        <div className="mt-6 text-center">
          <p className="text-sm text-gray-400">
            By continuing, you agree to our{' '}
            <a href="/terms-of-service" className="text-purple-500 hover:underline">
              Terms of Service
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default SetProfile;
