import React from 'react';
import { parseISO, format } from 'date-fns';
import './ChatBox.css';

const genderIcons = {
  Male: 'M', 
  Female: 'F', 
  'Non-Binary': 'NB',
  Other: '⚪'
};

const ChatBox = ({ messages, messagesEndRef, username }) => {
  const renderedMessages = messages.map((msg, index) => (
<div 
  key={index} 
  className={`p-3 rounded-md text-white self-end message ${msg.username === username ? 'bg-indigo-600' : 'bg-gray-700'}`}>
  <div className="text-sm message-header">
    <span className="font-bold message-username">{msg.username || 'Unknown User'}</span>
    <span className={`ml-2 text-xs ${msg.gender ? 'inline' : 'hidden'} gender-badge`}>
      {genderIcons[msg.gender]}
    </span>
  </div>
  <p className="mt-2 message-text">{msg.text}</p>
  <span className="text-xs text-gray-400 mt-1 message-timestamp">
    {msg.timestamp ? format(parseISO(msg.timestamp), 'p') : ''}
  </span>
</div>


  ));

  return (
<div className=" flex-1 overflow-y-auto p-4 space-y-4 bg-gradient-to-b from-black via-gray-900 to-gray-800 chat-box">
  {renderedMessages}
  <div ref={messagesEndRef} />
</div>

  );
};

export default ChatBox;
