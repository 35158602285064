import React, { useState, useEffect, useRef } from 'react';
import { connectSocket, getSocket } from '../services/socketService';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Header from '../components/Header';
import ChatBox from '../components/ChatBox';
import MessageInput from '../components/MessageInput';
import './Room.css';

const Room = () => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [coordinates, setCoordinates] = useState(null);
  const [room, setRoom] = useState('');
  const [userCount, setUserCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const messagesEndRef = useRef(null);
  const location = useLocation();
  const username = location.state?.username || 'Anonymous';
  const gender = location.state?.gender || 'Unknown';
  const navigate = useNavigate();  // Make sure to use this
  // Format room name for display
  const formatRoomName = (roomId) => {
    const parts = roomId.split('-');
    if (parts.length > 2) {
      const city = parts[0];
      const country = parts[1];
      return `${city}, ${country}`.toUpperCase();
    }
    return roomId.toUpperCase();
  };

  // Get user location
  const getUserLocation = () => {
    if (!navigator.geolocation) {
      console.error('Geolocation not supported by this browser.');
      setLoading(false);
      return;
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCoordinates({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        setLoading(false);
      },
      (error) => {
        console.error('Error getting location', error);
        setLoading(false);
      }
    );
  };

  // Initialize location on component mount
  useEffect(() => {
    getUserLocation();
  }, []);

  // Set up socket connection once coordinates are available
  useEffect(() => {
    if (coordinates) {
      const socket = connectSocket(coordinates.latitude, coordinates.longitude, username, gender);

      // Notify server when joining room
      socket.emit('join_room', { username, gender });

      // Handle messages from server
      socket.on('receive_message', (data) => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: data.text, timestamp: data.timestamp, username: data.username, gender: data.gender },
        ]);
      });

      // Handle room joining event
      socket.on('joined_room', (roomId) => {
        setRoom(roomId);
      });

      // Update user count in the room
      socket.on('user_count_update', (count) => {
        setUserCount(count);
      });

      // Handle errors from server
      socket.on('error', (error) => {
        console.error('Socket error:', error.message);
        navigate('/error', { state: { message: error.message } });
      });

      // Cleanup on component unmount
      return () => socket.disconnect();
    }
  }, [coordinates, username, gender, navigate]);

  // Send a message
  const sendMessage = () => {
    if (message.trim()) {
      getSocket().emit('send_message', { text: message, username, gender });
      setMessage('');
    }
  };

  // Handle keydown for sending messages
  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  // Leave the room
  const onLeaveRoom = () => {
    const socket = getSocket();
    if (socket) {
      socket.disconnect();
    }
    setMessages([]);
    setRoom('');
    setUserCount(0);
    navigate('/home', { state: { username, gender } });
  };

  // Scroll to the last message
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  // Loading screen
  if (loading || !room) {
    return (
      <div className="flex justify-center items-center h-screen bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white">
        <div className="text-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-purple-500 border-opacity-75 mb-6"></div>
          <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold">Loading...</h2>
          <p className="text-gray-400 mt-2 text-sm md:text-md">
            Please wait while we set things up for you.
          </p>
        </div>
      </div>
    );
  }

  // Main chat room UI
  return (
    <div className="flex flex-col h-screen bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white room-container">
      <Header room={formatRoomName(room)} userCount={userCount} onLeaveRoom={onLeaveRoom} />
      <ChatBox messages={messages} messagesEndRef={messagesEndRef} username={username} />
      <MessageInput
        message={message}
        setMessage={setMessage}
        handleKeyDown={handleKeyDown}
        sendMessage={sendMessage}
      />
    </div>
  );
};

export default Room;
