import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Marketplace.css';

const Marketplace = () => {
  const location = useLocation();
  const username = location.state?.username || 'Anonymous';
  const gender = location.state?.gender || '';
  const navigate = useNavigate();

  const handleLeaveMarketplace = () => {
    navigate('/home', { state: { username, gender } });
    console.log('Navigating back to home', username, gender);
  };

  return (
    <div className="marketplace-container">
      <div className="container mx-auto p-4 text-center">
        <h1 className="text-4xl font-bold mb-6">Marketplace Coming Soon</h1>
        <p className="text-lg mb-4">
          Our marketplace is under development and will be available soon! Stay tuned for exciting features and a seamless experience.
        </p>
        <button
          onClick={handleLeaveMarketplace}
          className="button bg-red-600 text-white px-4 py-2 rounded"
        >
          Go Back to Home
        </button>
      </div>
    </div>
  );
};

export default Marketplace;
