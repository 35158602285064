import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import './Landing.css';

const Landing = () => {
  const navigate = useNavigate();

  // Framer Motion Fade-In Animation
  const fadeIn = { initial: { opacity: 0 }, animate: { opacity: 1 }, transition: { duration: 1 } };

  return (
    <motion.div {...fadeIn} className="bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white">
      {/* Hero Section */}
      <motion.section
        {...fadeIn}
        className="min-h-screen flex flex-col items-center justify-center px-6 text-center"
      >
        <h1 className="text-4xl md:text-6xl font-extrabold mb-6">
          Welcome to <span className="text-purple-500">Unsaid</span>
        </h1>
        <p className="text-lg md:text-xl max-w-3xl mx-auto mb-8">
          Connect anonymously, share freely, be heard instantly.

        </p>
        <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
          <button
            onClick={() => navigate('/set-profile')}
            className="bg-purple-500 hover:bg-blue-500 text-white px-6 py-3 rounded-full font-semibold shadow-md transition"
          >
            Dive In
          </button>
          <button
  onClick={() => {
    const targetSection = document.querySelector('.features-section');
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: 'smooth' });
    }
  }}
  className="bg-gray-800 hover:bg-gray-700 text-white px-6 py-3 rounded-full font-semibold shadow-md transition"
>
  Explore Features
</button>

        </div>
      </motion.section>

      {/* Features Section */}
      <motion.section {...fadeIn} className="py-20 px-6 bg-gray-900 features-section">
        <h2 className="text-3xl md:text-5xl font-bold text-center mb-12">
          Why Choose <span className="text-purple-500">Unsaid</span>?
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-6xl mx-auto">
          {[
            { title: 'Anonymous Conversations', desc: 'Share your thoughts freely.', icon: '/assets/anonymous.svg' },
            { title: '5 km Radius Rooms', desc: 'Connect locally.', icon: '/assets/location.svg' },
            { title: 'Instant Connections', desc: 'Jump into conversations instantly.', icon: '/assets/connection.svg' },
            { title: 'Privacy First', desc: 'No tracking, no worries.', icon: '/assets/privacy.svg' },
          ].map((feature, idx) => (
            <div
              key={idx}
              className="bg-gray-800 p-6 rounded-lg text-center hover:scale-105 transition transform"
            >
              {/* <img src={feature.icon} alt={`${feature.title} icon`} loading="lazy" className="w-16 h-16 mx-auto mb-4" /> */}
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-400">{feature.desc}</p>
            </div>
          ))}
        </div>
      </motion.section>

      {/* Testimonials Section */}
      <motion.section {...fadeIn} className="py-20 px-6 bg-gradient-to-r from-gray-800 via-gray-700 to-gray-800">
        <h3 className="text-3xl md:text-5xl font-bold text-center mb-12">What Our Users Love</h3>
        <div className="flex flex-wrap justify-center gap-8 max-w-5xl mx-auto">
          {[
            { text: 'Unsaid redefines chatting.', name: 'Alex M.' },
            { text: 'The 5 km radius feature is genius!', name: 'Jamie S.' },
            { text: 'Finally, an app that lets me express freely.', name: 'Taylor L.' },
          ].map((testimonial, idx) => (
            <div key={idx} className="bg-gray-800 p-6 rounded-lg shadow-md w-80 text-center">
              <p className="italic text-gray-300">"{testimonial.text}"</p>
              <h4 className="mt-4 font-semibold text-purple-500">{testimonial.name}</h4>
            </div>
          ))}
        </div>
      </motion.section>

      {/* Call-to-Action Section */}
      <motion.section {...fadeIn} className="py-20 px-6 bg-purple-500 text-black text-center">
        <h2 className="text-3xl md:text-5xl font-bold mb-6">Your Voice, Your Way</h2>
        <p className="text-lg md:text-xl mb-8">
          Start your journey with <span className="font-bold">Unsaid</span>.
        </p>
        <button
          onClick={() => navigate('/set-profile')}
          className="bg-black text-purple-500 px-6 py-3 rounded-full font-semibold hover:bg-gray-900 transition"
        >
          Get Started
        </button>
      </motion.section>

      {/* Footer */}
      <footer className="bg-gray-900 py-6 text-center text-white">
  <div className="container mx-auto">
    <p className="text-sm mb-4">© 2024 Unsaid. All rights reserved.</p>
    

<div className="flex flex-col md:flex-row justify-center space-x-4 mb-4">
  <Link
    to="/privacy-policy"
    className="text-gray-400 hover:text-purple-500 transition cursor-pointer mb-2 md:mb-0"
  >
    Privacy Policy
  </Link>
  <Link
    to="/terms-of-service"
    className="text-gray-400 hover:text-purple-500 transition cursor-pointer"
  >
    Terms of Service
  </Link>
  <Link
    to="/contact-us"
    className="text-gray-400 hover:text-purple-500 transition cursor-pointer"
  >
    Contact Us
  </Link>
</div>

    
    <div className="mt-4 md:mt-0">
    <p className="text-sm">Developed by <a href="https://www.linkedin.com/in/iamjeel" className="text-purple-500 hover:text-purple-600 transition" target="_blank" rel="noopener noreferrer">Jeel Thumar</a></p>
    </div>
  </div>
</footer>
    </motion.div>
  );
};

export default Landing;
